/* @import '~antd/dist/antd.css'; */

.logo {
  float: left;
  width: 100%;
  padding: 8px 16px;
  background-color: #0b3a66;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}
